import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useRouter } from "./router/router";
import { GlobalStyles } from "./global";
import "./app.module.css";
import "./assets/icons/prevArr.svg";

const App = (props) => {
  const routing = useRouter(props);

  return (
    <>
      <GlobalStyles />
      <Router>{routing}</Router>
    </>
  );
};

export default App;
