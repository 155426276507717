import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const LazyHome = lazy(
  () =>
    import(
      "../components/pages/homePage/HomePage"
    ) /* webpackChunkName: 'Home' */
);

const LazyVendors = lazy(
  () =>
    import(
      "../components/pages/vendorsPage/VendorsPage"
    ) 
);
const LazyPolicy = lazy(
  () =>
    import(
      "../components/pages/policy/Policy"
    ) 
);

const LazyUnitrends = lazy(
  () =>
    import(
      "../components/pages/unitrends/Unitrends"
    ) 
);
const LazyMinerva = lazy(
  () =>
    import(
      "../components/pages/minerva/Minerva"
    ) 
);
const LazyMazebolt = lazy(
  () =>
    import(
      "../components/pages/mazebolt/Mazebolt"
    ) 
);
const LazyChameleon = lazy(
  () =>
    import(
      "../components/pages/chameleon/Chameleon"
    ) 
);
const LazyCypherdog = lazy(
  () =>
    import(
      "../components/pages/cypherdog/Cypherdog"
    ) 
);
const LazyAppriver = lazy(
  () =>
    import(
      "../components/pages/appriver/Appriver"
    ) 
);
const LazyRublon = lazy(
  () =>
    import(
      "../components/pages/rublon/Rublon"
    ) 
);
const LazyHeimdal = lazy(
  () =>
    import(
      "../components/pages/heimdal/Heimdal"
    ) 
);
const LazyElGo = lazy(
  () =>
    import(
      "../components/pages/elgo/Elgo"
    ) 
);
const LazyActivechat = lazy(
  () =>
    import(
      "../components/pages/activechat/ActiveChat"
    ) 
);
const Lazy404 = lazy(
  () =>
    import(
      "../components/pages/notFoundPage/NotFoundPage"
    ) /* webpackChunkName: '404' */
);

export const useRouter = () => {
  return (
    <Suspense
      fallback={
        <div>
          <p>Loading...</p>
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={LazyHome} />
        <Route exact path="/vendors" component={LazyVendors} />
        <Route exact path="/vendors/unitrends" component={LazyUnitrends} />
        <Route exact path="/vendors/minerva" component={LazyMinerva} />
        <Route exact path="/vendors/mazebolt" component={LazyMazebolt} />
        <Route exact path="/vendors/chameleon" component={LazyChameleon} />
        <Route exact path="/vendors/cypherdog" component={LazyCypherdog} />
        <Route exact path="/vendors/appriver" component={LazyAppriver} />
        <Route exact path="/vendors/rublon" component={LazyRublon} />
        <Route exact path="/vendors/heimdal" component={LazyHeimdal} />
        <Route exact path="/vendors/elgo" component={LazyElGo} />
        <Route exact path="/vendors/activechat" component={LazyActivechat} />
        <Route exact path="/policy" component={LazyPolicy} />
        <Route exact path="*" component={Lazy404} />
        {/* <Route exact path="*" component={LazyHome}  /> */}
      </Switch>
    </Suspense>
  );
};
