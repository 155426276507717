import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
html {
    --color-text: #fff;
    --color-green: #00D454;
    --gradient-green: linear-gradient(180deg, #00D454 0%, #00A843 100%);;
    --background: #000
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    transition: all 0.2s ease-in;
  }
  * {
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    scroll-behavior: smooth;
    font-family: "Noto Sans", sans-serif; 
    color: var(--color-text);
    outline: none;
  }
  p,
  span,
  h1,
  h2,
  h3,
  h4 {
    cursor: default;
  }
  
  button {
    outline: none;
    border: none;
    cursor: pointer;
  }
  input,
  textarea {
    border: none;
    background-color: transparent;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }
  ul {
    padding: 0;
  }
  a {
    color: var(--color-text);
    display: block;
    cursor: pointer;
  }
  img {
    display: block;
  }
  
  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  body {
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; 
    color: var(--color-text);
    margin: 0;
    padding: 0;
    font-family:"Noto Sans", sans-serif; 
    transition: all 0.3s easy-in;
  }
  .text, .text2{
    font-size: 16px;
    color: var(--color-text); 
    line-height: 1.4;
  }
  .section{
    padding-top: 100px;
    padding-bottom: 0px;
    width: 100vw;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .container{
    width: 85%;
    margin: 0 auto;
    max-width:346px;
  }
  .sectionTitle{
    font-size: 32px;
    font-weight: 900;
    text-align: left;
    margin: 30px 0;
    color: var(--color-green);
    position: relative;
    line-height: 1.2;
  }
 
  #carouselVendors .flickity-prev-next-button {
    top: 94%;
    width: 118px;
    height: 40px;
    position: absolute;
    background: transparent;
  }
  #carouselVendors .previous { 
    left: 4px; 
  }
  #carouselVendors .next { 
    right: 4px; 
  }
  #carouselVendors .flickity-button:disabled, #carouselVendors .flickity-button:disabled > svg {
    opacity:0;
    height:0px;
    width: 0;
    cursor: not-allowed;
    pointer-events:none;
  }
  #contacts {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  #versions {
    padding-top: 30px;
  }
  @media (min-width: 460px) {
    #carouselVendors .flickity-prev-next-button {
      top: 93%;
    }
    #carouselVendors .previous { 
      left: 8%; 
    }
    #carouselVendors .next { 
      right: 8%; 
    }
  }
  @media (min-width: 768px) {
    #carouselVendors .flickity-prev-next-button {
      top: 94%;
    }
    #carouselVendors .previous { 
      left: 8%; 
    }
    #carouselVendors .next { 
      right: 8%; 
    }
    .section{
      height: 100%;
      align-items: start;
    }
    .sectionTitle{
      font-size: 60px;
    }
    .container{
      width: 70vw;
      max-width: 70vw;
    }
    .text, .text2{
      font-size: 20px;
      color: var(--color-text); 
      line-height: 1.4;
      max-width: 80%;
    }
    .text2{
      max-width: 82%;
    }
    #carouselVendors .is-selected{
      opacity: 1;
    }
    #contacts {
      padding-bottom: 80px;
    }
    #fifth div{
      padding-bottom: 0px;
    }
    #listVendors button, #listVendors button a{
      width: 100%;
    }
    #listVendors .text{
      max-width: 100%;
    }
    #vendorsList .container{
      width: 80vw;
      max-width: 80vw;
    }
  }
  @media (min-width: 1200px) {
    #firstPartner .text2{
      font-size: 24px;
      max-width: 560px;
    }
    .container{
      width: 70vw;
      max-width: 70vw;
    }
    .text {
      max-width: 260px;
    }
    .text2{
      font-size: 18px;
      max-width: 430px;
    }
    #first .text {
      max-width: 400px;
    }
     #chameleon #logoPartner{
        height: 80px;
        margin-bottom: 40px;
      }
    
    }
    @media (min-width: 1380px) {
    
      .container{
        width: 70vw;
        max-width: 70vw;
      }
      .text {
        max-width: 310px;
      }
      .text2{
        max-width: 530px;
      }
      
      }
    @media (min-width: 1500px) {
      .sectionTitle{
        margin-bottom: 60px;
      }
      #first .sectionTitle{
        max-width: 540px;
        margin-bottom: 30px;
      }
  }
  `;
